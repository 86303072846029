<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="headline mb-n6 mt-6">アプリに関するよくある質問</div>
      </v-col>
      <v-col cols="12">
        <div v-for="(category, ii) in faqs" :key="ii">
          <template v-if="category.faqs && category.faqs.length > 0">
            <h3
              class="v-expansion-panel-header category-heading-color font-weight-regular px-0"
            >
              {{ category.name }}
            </h3>
            <v-expansion-panels>
              <v-expansion-panel
                class="px-0"
                v-for="(item, i) in category.faqs"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{ item.question }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-html="item.answer"
                  class="px-6 panel-default pb-3"
                >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQPage",
  created() {
    this.loading = true;
    this.$store.dispatch("FAQ_GET_ALL").finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters(["faqs"])
  }
};
</script>

<style lang="scss">
.category-heading-color {
  color: #909090 !important;
}
.panel-default {
  display: block !important;
  img {
    width: 100%;
  }
}
</style>
